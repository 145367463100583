import styled from 'styled-components';
import { motion } from 'framer-motion';

import { calcHeight } from 'assets/styles/mixins';

export const Inner = styled.div`
  position: relative;
  height: 80vh;
  ${calcHeight(80)};
  width: 100%;
  max-width: ${({ theme }) => theme.layout.maxContainerWidth};
  margin: 0 auto;
  padding: 5vh 8vw 10vh;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: ${({ theme }) => theme.zIndex.level1};
`;

export const Circle = styled(motion.div)`
  width: 35vw;
  height: 35vw;
  max-width: 35rem;
  max-height: 35rem;
  min-height: 20rem;
  min-width: 20rem;
  border: 2px solid ${({ theme }) => theme.colors.textLight};
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;
