import styled from 'styled-components';
import { motion } from 'framer-motion';
import { GatsbyImage } from 'gatsby-plugin-image';

import { calcHeight } from 'assets/styles/mixins';

export const Wrapper = styled(motion.div)`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  height: 100vh;
  ${calcHeight(100)};
  overflow: hidden;

  ::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    min-width: 100%;
    min-height: 100%;
    max-width: 100vw;
    max-height: 100vh;
    background-color: ${({ theme }) => theme.colors.backgroundDark};
    opacity: 0.25;
    z-index: ${({ theme }) => theme.zIndex.level1};
  }
`;

export const Image = styled(GatsbyImage)`
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  ${calcHeight(100)};
  width: 100vw;
  z-index: 1;
`;
