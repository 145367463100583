import { motion } from 'framer-motion';
import React, { FC } from 'react';

import type { MainImage } from 'types';
import { Wrapper, Image } from './HeroImage.style';
import { scale, imageVariants } from './variants';

interface HeroImageProps {
  readonly image: MainImage;
  readonly isHover?: boolean;
}

export const HeroImage: FC<HeroImageProps> = ({ image, isHover = false }) => {
  const { gatsbyImageData, alt } = image || {};

  return (
    <Wrapper initial="hidden" animate="visible" exit="exit" variants={scale}>
      <motion.div animate={isHover ? 'hover' : ''} variants={imageVariants}>
        <Image image={gatsbyImageData} alt={alt} objectFit="cover" />
      </motion.div>
    </Wrapper>
  );
};
