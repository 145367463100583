export const circleVariants = {
  hover: {
    scale: 1.1,
  },
  hidden: {
    scale: 0.4,
    opacity: 0,
  },
  visible: {
    opacity: 1,
    scale: 1,
  },
  exit: {
    opacity: 0,
    scale: 0.6,
  },
};
