export const scale = {
  hidden: {
    opacity: 0,
    scale: 1.1,
  },
  visible: {
    opacity: 1,
    scale: 1,
  },
  exit: {
    opacity: 0,
    scale: 1.1,
  },

  hover: {
    scale: 1.05,
  },
};

export const imageVariants = {
  hover: {
    scale: 1.05,
  },
};
