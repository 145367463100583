import React, { FC, useState } from 'react';

import { useContent } from 'providers/ContentProvider/ContentProvider';
import { HeroImage } from 'components/Molecules/HeroImage/HeroImage';
import { Text } from 'components/Atoms/Text/Text';
import { CTA } from 'components/Atoms/CTA/CTA';
import { SideNav } from 'components/Molecules/SideNav/SideNav';
import { useTranslations } from 'hooks/useTranslations';
import { getCurrentNavItem } from 'helpers';
import { Circle, Inner } from './Home.style';
import { circleVariants } from './variants';

export const Home: FC = () => {
  const [isCircleHover, setIsCircleHover] = useState(false);
  const { homePageContent } = useContent();
  const { menu } = useTranslations();
  const { image } = homePageContent || {};
  const [aboutItem, { path }] = getCurrentNavItem({ menu, elements: [1, 2] });

  return (
    <>
      <HeroImage image={image} isHover={isCircleHover} />
      <Inner>
        <CTA to={path}>
          <Circle
            whileHover="hover"
            initial="hidden"
            animate="visible"
            exit="exit"
            onHoverStart={() => setIsCircleHover(true)}
            onHoverEnd={() => setIsCircleHover(false)}
            variants={circleVariants}
          >
            <Text fontSize="2.4rem" fontWeight="regular" letterSpacing="2px" isUpper>
              enter
            </Text>
          </Circle>
        </CTA>
      </Inner>
      <SideNav subItem={aboutItem} />
    </>
  );
};
