import React, { FC } from 'react';
import { graphql, PageProps } from 'gatsby';

import { ContentProvider } from 'providers/ContentProvider/ContentProvider';
import { Home } from 'components/Organisms/Home/Home';
import { SEO } from 'components/Organisms/SEO/SEO';

import type { HomePageData } from 'types';

const IndexPage: FC<PageProps<HomePageData>> = ({ data }) => {
  const { seo, locale, metakeywords } = data.homePageContent;
  return (
    <>
      <SEO seo={seo} lang={locale} keywords={metakeywords} />
      <ContentProvider data={data}>
        <Home />
      </ContentProvider>
    </>
  );
};

export default IndexPage;

export const query = graphql`
  query HOME_PAGE_QUERY($locale: String!) {
    homePageContent: datoCmsHomePage(locale: { eq: $locale }) {
      locale
      metakeywords
      seo: seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      ...HomePage
    }
    socialIcons: allDatoCmsSocial(filter: { locale: { eq: $locale } }) {
      nodes {
        ...SocialIcon
      }
    }
    categories: allDatoCmsCategory(filter: { locale: { eq: $locale } }, sort: { fields: order }) {
      nodes {
        ...CategoryElement
      }
    }
  }
`;
